export enum PublicationState {
  Published = 'LIVE',
  Draft = 'PREVIEW'
}

export interface StrapiAttributes<T> {
  id: number
  attributes: T
}

export interface StrapiResponse<T> {
  data: T
  error?: any
}

export interface StrapiPagination {
  page?: number
  pageCount?: number
  pageSize?: number
  start?: number
  limit?: number
  total?: number
}

export interface StrapiResponseWithPagination<T> {
  data: T
  meta: {
    pagination: StrapiPagination
  }
}

export interface StrapiFindResponse<T> {
  data: T
}

type StrapiMediaWithFormats = {
  url: string
  width?: number
  height?: number
  name: string
  formats?: {
    thumbnail?: StrapiMediaWithFormats
    small?: StrapiMediaWithFormats
    medium?: StrapiMediaWithFormats
    large?: StrapiMediaWithFormats
  }
}

export type StrapiMedia = StrapiAttributes<StrapiMediaWithFormats>

export interface Locales {
  locale: string
  locales: string[]
  defaultLocale: string
}
