import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import { ArticleProps } from '../../../types/article'
import graphqlRequest from '../request'
import PageMetadata from '../components/metadata'
import {
  ComponentSectionsBanner,
  ComponentSectionsContentSection,
  ComponentSectionsContentSectionWithCardList,
  ComponentSectionsTechnologiesList,
  ComponentSectionsContentSectionWithList,
  ComponentSectionsFullWidthBanner,
  ComponentSectionsModernContactSection,
  ComponentSectionsRecommendationShowcases
} from '../components'

const document = `
  query Showcases($slug: String!, $locale: I18NLocaleCode, $publicationState: PublicationState!) {
    showcases(
        publicationState: $publicationState
        filters: { 
            slug: { 
                eq: $slug 
            } 
        }
        locale: $locale
    ) {
      data {
        id
        attributes {
          localizations {
              data {
                  attributes {
                    locale
                    slug
              }
            }
          }
          title
          metadata ${PageMetadata}
          components {
              __typename     
              ${ComponentSectionsBanner}    
              ${ComponentSectionsContentSection} 
              ${ComponentSectionsContentSectionWithList}
              ${ComponentSectionsContentSectionWithCardList}
              ${ComponentSectionsTechnologiesList}
              ${ComponentSectionsFullWidthBanner}
              ${ComponentSectionsModernContactSection}
              ${ComponentSectionsRecommendationShowcases}
          }
          createDate
          content
          keywords
          description
          tableOfContent
          linkOfCase
          industry
          team
          projectState
          country
          recommendations {
            data {
              id
              attributes {
                title
                description
                createdAt
                keywords
                slug
                previewImage {
                    data {
                        id
                        attributes {
                            width
                            height
                            url
                            name
                        }
                    }
                }
              }
            }
          }
        }
      }
    }
  }
`

const get = async ({
  slug,
  locale,
  publicationState = PublicationState.Published
}: {
  slug: string
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<ArticleProps>> =>
  graphqlRequest(document, { slug, locale, publicationState }).then((res) => res?.data?.showcases?.data?.[0])

export default get
