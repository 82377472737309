import { styledComponent, Box, Container, Link } from '@hermes/web-components'

export const FooterContainer = styledComponent(Box)(({ theme }) => ({
  marginTop: '65px',
  background: '#F8F9FD',
  [theme.breakpoints.down('sm')]: {}
}))

export const FooterWrapper = styledComponent(Container)(({ theme }) => ({
  paddingTop: '50px',
  paddingBottom: '50px',
  display: 'flex',
  position: 'relative',
  flexWrap: 'wrap',
  gap: '70px',
  [theme.breakpoints.up('lg')]: {
    flexWrap: 'nowrap'
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0',
    paddingTop: '35px',
    paddingBottom: '35px',
    gap: '40px',
    marginTop: 0
  }
}))

export const SocialLinkWrapper = styledComponent(Box)<{
  href?: string
  target?: string
}>({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  div: {
    display: 'flex',
    alignItems: 'center'
  }
})

export const FooterLink = styledComponent(Link)<{
  component?: string
}>(({ theme }) => ({
  textDecoration: 'none',
  p: {
    color: theme.palette.typography.primary,
    '&:hover': {
      color: `${theme.palette.primary.main} !important`
    }
  }
}))

export const FooterLiner = styledComponent(Box)(({ theme }) => ({
  marginTop: '1px',
  backgroundColor: theme.palette.background.default,
  borderTop: `0.2px solid ${theme.palette.additional.border}`,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: '80px'
  }
}))
