import { createBreakpoints } from '@mui/system'

export default function getBreakpoints() {
  return createBreakpoints({
    values: {
      xs: 620,
      sm: 720,
      md: 840,
      lg: 1080,
      xl: 1392
    }
  })
}
