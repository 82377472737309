import { PaletteOptionsType } from '../typing/theme'

const palette: PaletteOptionsType = {
  primary: {
    main: '#35519E',
    light: '#367BBB',
    lightest: '#769AFF'
  },
  secondary: {
    main: '#44A8CE',
    light: '#47BFDA',
    lightest: '#47BFDA'
  },
  success: {
    main: '#24C231'
  },
  warning: {
    main: '#FFC107'
  },
  error: {
    main: '#FF0000'
  },
  background: {
    default: '#FFFFFF',
    paper: '#FCFDFF'
  },
  typography: {
    main: '#FFFFFF',
    primary: '#000000',
    secondary: '#595959'
  },
  additional: {
    background: '#EAEEF9',
    paperLinear: 'linear-gradient(180deg, #FFF 14.81%, #EAEEF9 305.68%)',
    border: '#3A5399',
    backgroundLight: '#EFF3FE'
  },
  divider: '#dde1e7'
}

export default palette
