export const StrapiImageObject = `
    {
        data {
            id
            attributes {
                height
                width
                url
                name
                formats
            }
        }
    }
`

export const ComponentSectionsHero = `
    ...on ComponentSectionsHero {
        title
        subtitle
        contactButton
        align
        teamPhotos ${StrapiImageObject}
        experienceCounter {
            years
            projects
            clients
            countries
        }
        experienceAchievementsCounter {
            years
            peopleInTeam
            achievementsLogos ${StrapiImageObject}
        }
    }
`

export const ComponentSectionsCustomersThink = `
    ...on ComponentSectionsCustomersThink {
        title,
        reviews (pagination: { limit: 100 }) {
            data {
                attributes {
                    companyName
                    createDate
                    review
                    reviewedOnClutch
                    reviewerAvatar ${StrapiImageObject}
                    reviewerName
                    reviewRating
                    reviewUrl
                }
            }
        }
    }
`

export const ComponentSectionsWeProvide = `
    ...on ComponentSectionsWeProvide {
        title
        list {
            id
            title
            subtitle
        }
    }
`

export const ComponentSectionsModels = `
    ...on ComponentSectionsModels {
        title
        techStackTitle
        techStack (pagination: { limit: 100 }) {
            title
            type
            techLogo ${StrapiImageObject}
        }
        models {
            id
            title
            description
            titleColor
            titleIcon ${StrapiImageObject}
        }
    }
`

export const ComponentSectionsShowcases = `
    ...on ComponentSectionsShowcases {
        title
    }
`

export const ComponentSectionsTrustedBy = `
    ...on ComponentSectionsTrustedBy {
        title
        icons (pagination: { limit: 100 }) {
            url
            image ${StrapiImageObject}
        }
    }
`

export const ComponentSectionsBlog = `
    ...on ComponentSectionsBlog {
        title
    }
`

export const ComponentComponentsGetInTouch = `
    ...on ComponentComponentsGetInTouch {
        title
        description
    }
`

export const ComponentSectionsClutchAchievements = `
    ...on ComponentSectionsClutchAchievements {
        clutchTitle
        clutchAchievements (pagination: { limit: 100 }) {
            id
            url
            image ${StrapiImageObject}
        }
    }
`

export const ComponentAtomsRichText = `
    ...on ComponentAtomsRichText {
        id
        content
        textAlign
        width
        breakpointFullWidth
    }
`

export const ComponentGeneratorComponentsHeading = `
    ...on ComponentGeneratorComponentsHeading {
        id
        headingTitle
        headingId
        headingSubtitle
        headingSubtitleAlign
        href
    }
`

export const ComponentGeneratorComponentsCounterCardList = `
    ...on ComponentGeneratorComponentsCounterCardList {
        id
        counterCardList {
            id
            count
            countTitle
            content
        }
    }
`

export const ComponentGeneratorComponentsBorderedCard = `
    ...on ComponentGeneratorComponentsBorderedCard {
        id
        cardContent
    }
`

export const ComponentGeneratorComponentsList = `
    ...on ComponentGeneratorComponentsList {
        id
        listType
        listItems {
            startIcon ${StrapiImageObject}
            content
            textAlign
        }
    }
`

export const ComponentGeneratorComponentsQuoteBlock = `
    ...on ComponentGeneratorComponentsQuoteBlock {
        id
        quote
    }
`

export const ComponentAtomsTitle = `
    ...on ComponentAtomsTitle {
        title
        link
    }
`

export const ComponentGeneratorComponentsSolutionsList = `
    ...on ComponentGeneratorComponentsSolutionsList {
        solutionsTitle
        challengesTitle
        solutions {
            content
        }
        challenges {
            content
        }
    }
`

export const ComponentComponentsContactForm = `
    ...on ComponentComponentsContactForm {
        title
        description
    }
`

export const ComponentGeneratorComponentsBorderedCardList = `
    ...on ComponentGeneratorComponentsBorderedCardList {
        cardsList {
            cardContent
        }
    }
`

export const ComponentComponentsTeamPhotos = `
    ...on ComponentComponentsTeamPhotos {
        photos ${StrapiImageObject}
    }
`

export const ComponentGeneratorComponentsInfoCardList = `
    ...on ComponentGeneratorComponentsInfoCardList {
        cardsList {
            title
            content
            icon ${StrapiImageObject}
        }
    }
`

export const ComponentComponentsCkEditor = `
    ...on ComponentComponentsCkEditor {
        ckeditorContent
    }
`

export const ComponentComponentsRoadmap = `
    ...on ComponentComponentsRoadmap {
        list {
            label
            icon ${StrapiImageObject}
        }
    }
`

export const ComponentSectionsQuoteBlockWithImages = `
    ...on ComponentSectionsQuoteBlockWithImages {
        text
        imagesPosition
        images ${StrapiImageObject}
    }
`
export const ComponentSectionsCertificates = `
    ...on ComponentSectionsCertificates{
        certificatesList (pagination: { limit: 100 }) {
            title
            content
            img ${StrapiImageObject}
        }
    }
`
export const ComponentGeneratorComponentsStepsList = `
    ...on ComponentGeneratorComponentsStepsList {
        id
        stepList {
          id
          mobileIcon ${StrapiImageObject}
          content
        }
    }
`
export const ComponentSectionsFrameworkLayerDiagram = `
    ...on ComponentSectionsFrameworkLayerDiagram{
        frameworkLayerDiagramItems {
            title
            description
            iconsOfItem ${StrapiImageObject}
        }
    }
`

export const ComponentSectionsPuzzlePlatform = `
    ...on ComponentSectionsPuzzlePlatform{
        puzzleItems {
            content
            icon ${StrapiImageObject}
        }
    }
`
export const ComponentGeneratorComponentsSupportCardList = `
    ...on ComponentGeneratorComponentsSupportCardList {
        id
        supportCardList {
            id
            title
            contentList
        }
   }
`

export const ComponentSectionsBanner = `
    ...on ComponentSectionsBanner{
        titleBanner
        subtitleBanner
        buttonTitleBanner
        linkBanner
        imgBanner ${StrapiImageObject}
    }
`
export const ComponentSectionsProjectsSlider = `
    ...on ComponentSectionsProjectsSlider{
          title
          type
          titleUrl
    }
`
export const ComponentGeneratorComponentsOperationsList = `
    ...on ComponentGeneratorComponentsOperationsList {
        id
        operationsList {
            id
            level
            title
            content
            icon ${StrapiImageObject}
            jobTitle
            jobSalary
        }
    }
`

export const ComponentGeneratorComponentsServiceCardList = `
    ...on ComponentGeneratorComponentsServiceCardList{
        serviceCardList {
            id
            title
            content
            icon ${StrapiImageObject}
        }
    }
`

export const ComponentComponentsExpandableList = `
    ...on ComponentComponentsExpandableList{
        expandableList {
            id
            title
            content
        }
    }
`

export const ComponentComponentsServiceSlider = `
    ...on ComponentComponentsServiceSlider{
        serviceSlider {
            id
            title
            content
            mobileIcon ${StrapiImageObject}
            serviceSliderMediaItem {
                id
                title
                icon ${StrapiImageObject}
            }
        }
    }
`
export const ComponentComponentsTechStackSlider = `
    ...on ComponentComponentsTechStackSlider{
        techStackList {
            id
            technologyName
            techIcon ${StrapiImageObject}
            description
            descriptionList {
                id
                startIcon ${StrapiImageObject}
                content 
                textAlign
            }
        }
    }
`
export const ComponentSectionsCaseOverview = `
...on ComponentSectionsCaseOverview { 
    titleCaseOverview
    subtitleCaseOverview
    imgCaseOverview ${StrapiImageObject}
    bgColorCaseOverview
    contentBlockCaseOverview
    readMoreCaseOverview{
    label 
    url}
    feedbackCaseOverview
    fullNameAuthorFeedback
    avatarAuthorFeedback${StrapiImageObject}
    positionAuthorFeedback
    featureListCaseOverview {
    title
    content
    }
}`

export const ComponentSectionsContentSection = `
    ...on ComponentSectionsContentSection{
        titleOfContentSection
        subtitleOfContentSection
        contentOfContentSection
        variantCard
        cardContent
    }
`
export const ComponentSectionsContentSectionWithList = ` 
    ...on ComponentSectionsContentSectionWithList {
        titleContentSectionWithList
        subtitleOfContentSectionWithList
        listItemsType
        listContentSection {
            startIcon ${StrapiImageObject}
            content
            textAlign
        }   
    }
`

export const ComponentSectionsContentSectionWithCardList = `
    ...on ComponentSectionsContentSectionWithCardList {
        titleContentSectionWithCardList
        subtitleOfContentSectionWithCardList
        contentOfContentSectionWithCardList
        cardList {
            id
            title
            content
        }
    }
`
export const ComponentSectionsTechnologiesList = `
    ...on ComponentSectionsTechnologiesList {
        titleTechnologiesList
        descriptionTechnologiesList
        technologies (pagination: { limit: 50 }){
        data{
            id
            attributes {
                icon ${StrapiImageObject}    
                title
            }
        }}
    
    }
`
export const ComponentSectionsFullWidthBanner = `   
    ...on ComponentSectionsFullWidthBanner {
        imgBanner ${StrapiImageObject}
        imgBannerMobile ${StrapiImageObject}
        isImgInContainer
    }
`
export const ComponentSectionsModernContactSection = `
    ...on ComponentSectionsModernContactSection {
        titleModernContactSection
        avatarProfileContact ${StrapiImageObject}
        fullNameContact
        positionProfileContact
        descriptionProfileContact
        emailContactProfile {
            label
            url
        }
        linkedInContactProfile {
            label
            url
        }

    }
`
export const ComponentSectionsRecommendationShowcases = `
    ...on ComponentSectionsRecommendationShowcases {
        showcases {
            data {
                id
                attributes {
                    title
                    description
                    createdAt
                    keywords
                    slug
                    previewImage {
                        data {
                            id
                            attributes {
                                width
                                height
                                url
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`

export const ComponentComponentsInsightsLinkBanner = `
    ...on ComponentComponentsInsightsLinkBanner {
        title
        description
        url
        buttonText
    }
`
export const ComponentComponentsMinoBanner = `
    ...on ComponentComponentsMinoBanner{
        title
        description
    }
`
export const ComponentComponentsInsightsQuoteBlock = `
    ...on ComponentComponentsInsightsQuoteBlock{
        quote
        authorName
        authorPosition
    }
`
export const ComponentComponentsFAQ = `
    ...on ComponentComponentsFaq{
        faqList {
          id
          title
          description
        }
    }
`

export const ComponentComponentsInsightsBookBanner = `
    ...on ComponentComponentsInsightsBookBanner{
       bookImage ${StrapiImageObject},
       title,
       description,
       bookFile {
        data {
          id
          attributes {
            url
          }
        }
       },
       bannerButtonText
    }
`

export const ComponentComponentsClutchReviewsWidget = `
    ...on ComponentComponentsClutchReviewsWidget {
        dataReviews
    }
`
