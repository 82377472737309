import { theme, Theme } from '@hermes/web-components'

const RobotoWoffPath = '/fonts/Roboto/woff'
const RobotoWoff2Path = '/fonts/Roboto/woff2'

/* Roboto Thin */
const RobotoThin = `  
  {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('${RobotoWoffPath}/Roboto-Thin.woff') format('woff'),
         url('${RobotoWoff2Path}/Roboto-Thin.woff2') format('woff2');
    font-display: swap;
  }
`

/* Roboto Light */
const RobotoLight = `
  {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('${RobotoWoffPath}/Roboto-Light.woff') format('woff'),
         url('${RobotoWoff2Path}/Roboto-Light.woff2') format('woff2');
    font-display: swap;
  }
`

/* Roboto Regular */
const RobotoRegular = `
  {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('${RobotoWoffPath}/Roboto-Regular.woff') format('woff'),
         url('${RobotoWoff2Path}/Roboto-Regular.woff2') format('woff2');
    font-display: swap;
  }
`

/* Roboto Medium */
const RobotoMedium = `
  {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('${RobotoWoffPath}/Roboto-Medium.woff') format('woff'),
         url('${RobotoWoff2Path}/Roboto-Medium.woff2') format('woff2');
    font-display: swap;
  }
`

/* Roboto Bold */
const RobotoBold = `
  {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('${RobotoWoffPath}/Roboto-Bold.woff') format('woff'),
         url('${RobotoWoff2Path}/Roboto-Bold.woff2') format('woff2');
    font-display: swap;
  }
`

/* Roboto Black */
const RobotoBlack = `
  {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('${RobotoWoffPath}/Roboto-Black.woff') format('woff'),
         url('${RobotoWoff2Path}/Roboto-Black.woff2') format('woff2');
    font-display: swap;
  }
`

const fallbackFontFamily = ['sans-serif']

const fontFamily = ['Roboto', ...fallbackFontFamily].join(',')

const getTheme = (): Theme => ({
  ...theme,
  typography: {
    ...theme.typography,
    fontFamily
  },
  components: {
    ...theme.components,
    MuiCssBaseline: {
      ...theme.components?.MuiCssBaseline,
      styleOverrides: `
        @font-face ${RobotoThin}
        @font-face ${RobotoLight}
        @font-face ${RobotoRegular}
        @font-face ${RobotoMedium}
        @font-face ${RobotoBold}
        @font-face ${RobotoBlack}
        html {
          scroll-padding-top: 100px;
        }
        body {
          background-color: ${theme.palette.background.default};
          word-spacing: 1px;
          opacity: 1;
          overflow-x: hidden;
          a {
            text-decoration: none;
          }
        }
        * {
          font-family: ${fontFamily};
        }
      `
    }
  }
})

export default getTheme
