import getConfigVariable from '../../utils/getConfigVariable'

const sendEmailEndpoint = getConfigVariable('SEND_EMAIL_ENDPOINT')

interface SendEmailPayload {
  fromEmail: string
  fromName: string
  message: string
  hearAboutUs?: string
  company?: string
}

const sendEmail = async (payload: SendEmailPayload) => {
  let { message } = payload
  if (payload.hearAboutUs) {
    message += `\n\nHear about us: ${payload.hearAboutUs}`
  }

  if (payload.company) {
    message += `\n\nCompany: ${payload.company}`
  }

  const finalPayload: SendEmailPayload = {
    fromEmail: payload.fromEmail,
    fromName: payload.fromName,
    message
  }

  const res = await fetch(sendEmailEndpoint, {
    method: 'POST',
    body: JSON.stringify(finalPayload)
  })

  return res.status
}

export { sendEmail }
