import { Components } from '@mui/material/styles/components'
import Chevron from '../../icons/Chevron'

export default function AccordionSummary(): Components['MuiAccordionSummary'] {
  return {
    defaultProps: {
      expandIcon: Chevron({ width: '26px', height: '26px' })
    },
    styleOverrides: {
      root: {
        padding: 0
      }
    }
  }
}
