import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Button(theme: Theme): Components['MuiButton'] {
  return {
    defaultProps: {
      disableElevation: true
    },
    styleOverrides: {
      root: {
        fontFamily: 'Roboto',
        transition: '0.1s',
        textTransform: 'uppercase'
      },
      outlined: {
        border: `1px solid ${theme.palette.typography.primary}`,
        color: theme.palette.typography.primary,
        borderRadius: '25px',
        padding: '20px 25px',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        backgroundColor: theme.palette.background.default,
        svg: {
          fill: theme.palette.typography.primary
        },
        '&:hover': {
          border: '1px solid transparent',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.typography.main,
          fill: theme.palette.typography.main,
          span: {
            color: theme.palette.typography.main
          }
        },
        '&:hover svg': {
          fill: theme.palette.typography.main
        }
      },
      contained: {
        padding: '20px 75px',
        borderRadius: '25px',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        }
      }
    },
    variants: [
      {
        props: { variant: 'gradientModern' },
        style: {
          position: 'relative',
          color: theme.palette.common.white,
          borderRadius: '26px',
          padding: '12px 32px',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '16px',
          border: '1px solid transparent',
          background: 'linear-gradient(90deg, #46BED9 0%, #769AFF 50.25%, #3A5399 100%) border-box',
          '&:hover': {
            background:
              'linear-gradient(90deg, rgba(70, 190, 217, 0.5) 0%, rgba(118, 154, 255, 0.5) 50.25%, rgba(58, 83, 153, 0.5) 100%)  border-box'
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: -1,
            mask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
            borderRadius: '26px',
            border: '1px solid transparent',
            background: 'linear-gradient(90deg, #46BED9 0%, #769AFF 50%, #36529F 84%) border-box'
          }
        }
      },
      {
        props: { variant: 'outlineWithGradientModern' },
        style: {
          position: 'relative',
          background: 'linear-gradient(90deg, #2FB0D5 0%, #4787E4 50%, #36529F 84%)',
          backgroundClip: 'text',
          color: 'transparent',
          borderRadius: '25px',
          padding: '20px 25px',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '16px',
          boxSizing: 'border-box',
          border: '1px solid transparent',
          '&::before': {
            content: '""',
            position: 'absolute',
            height: '100%',
            zIndex: -1,
            width: '100%',
            borderRadius: '25px',
            border: '1px solid transparent',
            mask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
            maskComposite: 'exclude',
            background: 'linear-gradient(90deg, #42A6CB 5.5%, #4787E4 55%, #36529F 99.5%) border-box'
          },
          '&:hover': {
            backgroundClip: 'none',
            background: 'linear-gradient(90deg, #46BED9 0%, #769AFF 53.5%, #35519E 100%) border-box',
            color: theme.palette.typography.main
          },
          '&.Mui-disabled': {
            background: 'none',
            color: '#BDBDBD',
            border: '1px solid #BDBDBD',
            '&::before': {
              background: 'none'
            }
          }
        }
      },
      {
        props: { variant: 'outlineWithGradient' },
        style: {
          position: 'relative',
          background: 'linear-gradient(90deg, #2FB0D5 0%, #4787E4 50%, #36529F 84%)',
          backgroundClip: 'text',
          color: 'transparent',
          borderRadius: '25px',
          padding: '20px 25px',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          boxSizing: 'border-box',
          border: '1px solid transparent',
          '&::before': {
            content: '""',
            position: 'absolute',
            height: '100%',
            zIndex: -1,
            width: '100%',
            borderRadius: '25px',
            border: '1px solid transparent',
            backgroundImage:
              'linear-gradient(white, white), linear-gradient(90deg, #42A6CB 5.5%, #4787E4 55%, #36529F 99.5%)',
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box'
          },
          '&:hover': {
            backgroundClip: 'none',
            background: theme.palette.primary.main,
            color: theme.palette.typography.main,
            fill: theme.palette.typography.main
          }
        }
      },
      {
        props: { variant: 'outlineWhite' },
        style: {
          border: `1px solid ${theme.palette.background.default}`,
          color: theme.palette.typography.main,
          borderRadius: '25px',
          padding: '20px 25px',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          textTransform: 'uppercase',
          '&:hover': {
            border: `1px solid ${theme.palette.background.default}`
          }
        }
      },
      {
        props: { variant: 'icon' },
        style: {
          minWidth: 'auto',
          width: '32px',
          height: '32px',
          padding: 0,
          borderRadius: '50%'
        }
      },
      {
        props: { variant: 'ghost' },
        style: {
          minWidth: 'auto',
          padding: 0,
          margin: 0,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    ]
  }
}
