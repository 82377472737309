import { createContext, ReactNode, useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { useAppConfig } from './AppConfigProvider'

interface LocalizationLink {
  href: string
  label: string
}

type GlobalStateContextProps = {
  getInTouchOpen: boolean
  handleOpenGetInTouch: () => void
  handleCloseGetInTouch: () => void
  headerLocalizationLinks: LocalizationLink[]
  handleChangeHeaderLocalizationLinks: (links: LocalizationLink[]) => void
}

const GlobalStateContext = createContext<GlobalStateContextProps>({
  getInTouchOpen: false
} as GlobalStateContextProps)

const GlobalStateProvider = ({ children }: { children: ReactNode }) => {
  const { locales } = useAppConfig()
  const router = useRouter()
  const [getInTouchOpen, setGetInTouchOpen] = useState(false)
  const [headerLocalizationLinks, setHeaderLocalizationLinks] = useState(
    locales.map((loc) => ({
      href: `/${loc}${router.asPath.length > 1 ? router.asPath : ''}`,
      label: loc.toUpperCase()
    }))
  )

  const handleOpenGetInTouch = () => setGetInTouchOpen(true)
  const handleCloseGetInTouch = () => {
    setTimeout(() => setGetInTouchOpen(false), 300)
  }
  const handleChangeHeaderLocalizationLinks = (links: LocalizationLink[]) => setHeaderLocalizationLinks(links)

  return (
    <GlobalStateContext.Provider
      value={{
        getInTouchOpen,
        handleOpenGetInTouch,
        handleCloseGetInTouch,
        headerLocalizationLinks,
        handleChangeHeaderLocalizationLinks
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}

const useGlobalState = () => {
  const context = useContext(GlobalStateContext)
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider')
  }
  return context
}

export { useGlobalState, GlobalStateProvider }
