import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import PageMetadata from '../components/metadata'
import graphqlRequest from '../request'

const document = `
    query BlogsPage($locale: I18NLocaleCode!, $publicationState: PublicationState!) {
        blogsPage(locale: $locale, publicationState: $publicationState) {
            data {
                id
                attributes {
                    hero {
                        id
                        variant
                        contactButton
                        subtitle
                        title
                    }
                    getInTouch {
                        id
                        title
                        description
                    }
                    metadata ${PageMetadata}
                    title
                    description
                }
            }
        }
    }
`

const getBlogsPage = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<any>> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.blogsPage?.data)

export default getBlogsPage
