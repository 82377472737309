import getConfig from 'next/config'

export type ConfigKey =
  | 'POEDITOR_API_URL'
  | 'POEDITOR_ACCESS_KEY'
  | 'POEDITOR_PROJECT_ID'
  | 'STRAPI_URL'
  | 'STRAPI_TOKEN'
  | 'SEND_EMAIL_ENDPOINT'
  | 'STAGE'

export default function getConfigVariable(key: ConfigKey) {
  const { publicRuntimeConfig } = getConfig()

  return publicRuntimeConfig[key] || ''
}

export const getServerConfigVariable = (key: ConfigKey): string => {
  const { serverRuntimeConfig } = getConfig()

  return serverRuntimeConfig[key] || ''
}
