import { Components } from '@mui/material/styles/components'
import CheckboxCheckedIcon from '../../icons/CheckboxChecked'
import CheckboxIcon from '../../icons/Checkbox'

export default function Checkbox(): Components['MuiCheckbox'] {
  return {
    defaultProps: {
      icon: CheckboxIcon({ width: '21px', height: '21px' }),
      checkedIcon: CheckboxCheckedIcon({ width: '21px', height: '21px' })
    },
    styleOverrides: {
      root: {
        span: {
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center'
        },
        padding: 0,
        width: '21px',
        height: '21px',
        input: {
          width: '21px',
          height: '21px'
        }
      }
    }
  }
}
