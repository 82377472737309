import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import { ArticleProps } from '../../../types/article'
import graphqlRequest from '../request'

const document = `
  query Showcases($locale: I18NLocaleCode, $publicationState: PublicationState!) {
    showcases(publicationState: $publicationState, locale: $locale, pagination: {
        pageSize: 10
    }) {
        data {
            id
            attributes {
                title
                slug
                description
            }
        }
    }
  }
`

const get = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<ArticleProps>[]> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.showcases?.data)

export default get
