import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import graphqlRequest from '../request'
import { ProposalProps } from '../../../containers/Proposal'
import PageMetadata from '../components/metadata'
import { StrapiImageObject } from '../components'

const document = `
  query Proposal($slug: String!, $locale: I18NLocaleCode, $publicationState: PublicationState!) {
    proposals(
        publicationState: $publicationState
        filters: { 
            slug: { 
                eq: $slug 
            } 
        }
        locale: $locale
    ) {
      data {
        id
        attributes {
          metadata ${PageMetadata}
          proposalList {
            id,
            title,
            menuTitle,
            content {
              ckeditorContent
            }
            variant
            reviews (pagination: { limit: 100 }) {
              data {
                id,
                attributes { 
                    companyName
                    createDate
                    review
                    reviewedOnClutch
                    reviewerAvatar ${StrapiImageObject}
                    reviewerName
                    reviewRating
                    reviewUrl
                }
              }
            }
          }
        }
      }
    }
  }
`
const get = async ({
  slug,
  locale,
  publicationState = PublicationState.Published
}: {
  slug: string
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<ProposalProps>> =>
  graphqlRequest(document, { slug, locale, publicationState }).then((res) => res?.data?.proposals?.data?.[0])

export default get
