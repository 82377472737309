import { createTheme } from '@mui/material/styles'
import getOverrides from './overrides'
import palette from './palette'
import typography from './typography'
import breakpoints from './breakpoints'

const theme = createTheme({ palette })

theme.components = getOverrides(theme)
theme.typography = typography(theme)
theme.breakpoints = breakpoints()

export { theme }
