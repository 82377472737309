import getConfigVariable from '../../utils/getConfigVariable'

const strapiUrl = getConfigVariable('STRAPI_URL')
const strapiToken = getConfigVariable('STRAPI_TOKEN')

const graphqlRequest = async (query: string, variables: any) =>
  fetch(`${strapiUrl}/graphql`, {
    body: JSON.stringify({
      query,
      variables
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${strapiToken}`
    },
    method: 'POST'
  }).then((res) => res.json())

export default graphqlRequest
