import { Box, Container, Link, Menu, styledComponent } from '@hermes/web-components'

export const HeaderContainer = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'scrolled'
})<{ scrolled?: boolean }>(({ theme, scrolled }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 999,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: theme.palette.background.default,
  transition: '.3s',
  maxHeight: '74px',
  height: '74px',
  borderBottom: `0.2px solid ${scrolled ? theme.palette.additional.border : 'transparent'}`
}))

export const HeaderContent = styledComponent(Container)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '1080px',
  width: '100%'
})

export const HeaderLinks = styledComponent(Box)(({ theme }) => ({
  display: 'flex',
  gap: '30px',
  [theme.breakpoints.down('lg')]: {
    gap: '20px'
  }
}))

export const NavButton = styledComponent(Link, {
  shouldForwardProp: (prop) => prop !== 'active'
})<{ active?: boolean }>(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.typography.primary,
  fontStyle: 'normal',
  padding: 0,
  minWidth: 'auto',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  textDecoration: 'none',
  borderBottom: '2px solid transparent',
  paddingBottom: '-2px',
  transition: '0.1s 0.1s ease-in-out',
  ...(active
    ? {
        color: theme.palette.primary.light
      }
    : {}),
  '&:hover': {
    color: theme.palette.primary.light
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: '13px'
  }
}))

export const MobileMenu = styledComponent(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: '10px',
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.additional.border}`
  }
}))
