import type { CSSProperties, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@hermes/web-components'

export default function useTranslateMessage() {
  const { formatMessage } = useIntl()
  return (
    id: string,
    values?: Record<string, string | number | boolean>,
    style?: {
      bold?: CSSProperties
      medium?: CSSProperties
    }
  ): string =>
    formatMessage(
      { id, defaultMessage: values?.defaultMessage as string },
      {
        ...(values || {}),
        b: (str: ReactNode) => {
          if (!values?.skipBold) return <b style={{ color: 'inherit', ...style?.bold }}>{str}</b>
          return str
        },
        medium: (str: ReactNode) => (
          <Typography
            sx={{ fontSize: '21.36px', fontWeight: '700', ...style?.medium }}
            component="span"
            color="inherir"
          >
            {str}
          </Typography>
        ),
        linebreak: <br />
      },
      {
        formatters: {
          getNumberFormat: () => Intl.NumberFormat('en'),
          getDateTimeFormat: (...args: ConstructorParameters<typeof Intl.DateTimeFormat>) =>
            new Intl.DateTimeFormat(...args),
          getPluralRules: (...args: ConstructorParameters<typeof Intl.PluralRules>) => new Intl.PluralRules(...args)
        }
      }
    ) as string
}
