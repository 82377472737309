import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Table(theme: Theme): Components['MuiTable'] {
  return {
    styleOverrides: {
      root: {
        border: `1px solid ${theme.palette.typography.secondary}`
      }
    }
  }
}
