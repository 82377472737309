import { AboutUsPagesContainerProps } from '../../../containers/AboutUsPages/index'
import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import {
  ComponentAtomsRichText,
  ComponentComponentsCkEditor,
  ComponentComponentsClutchReviewsWidget,
  ComponentComponentsContactForm,
  ComponentComponentsExpandableList,
  ComponentComponentsGetInTouch,
  ComponentComponentsRoadmap,
  ComponentComponentsServiceSlider,
  ComponentComponentsTeamPhotos,
  ComponentComponentsTechStackSlider,
  ComponentGeneratorComponentsBorderedCard,
  ComponentGeneratorComponentsBorderedCardList,
  ComponentGeneratorComponentsCounterCardList,
  ComponentGeneratorComponentsHeading,
  ComponentGeneratorComponentsInfoCardList,
  ComponentGeneratorComponentsList,
  ComponentGeneratorComponentsOperationsList,
  ComponentGeneratorComponentsQuoteBlock,
  ComponentGeneratorComponentsServiceCardList,
  ComponentGeneratorComponentsSolutionsList,
  ComponentGeneratorComponentsStepsList,
  ComponentGeneratorComponentsSupportCardList,
  ComponentSectionsBlog,
  ComponentSectionsCaseOverview,
  ComponentSectionsCertificates,
  ComponentSectionsClutchAchievements,
  ComponentSectionsCustomersThink,
  ComponentSectionsFrameworkLayerDiagram,
  ComponentSectionsHero,
  ComponentSectionsModels,
  ComponentSectionsProjectsSlider,
  ComponentSectionsPuzzlePlatform,
  ComponentSectionsQuoteBlockWithImages,
  ComponentSectionsShowcases,
  ComponentSectionsTrustedBy,
  ComponentSectionsWeProvide
} from '../components'
import PageMetadata from '../components/metadata'
import graphqlRequest from '../request'

const document = `
    query AboutUsPages($slug: String!, $locale: I18NLocaleCode, $publicationState: PublicationState!) {
        aboutUsPages(
            publicationState: $publicationState
            locale: $locale
            filters: { 
                slug: { 
                    eq: $slug 
                } 
            }
        ) {
            data {
                attributes {
                    metadata ${PageMetadata}
                    components {
                        __typename
                        ${ComponentAtomsRichText}
                        ${ComponentComponentsCkEditor}
                        ${ComponentComponentsContactForm}
                        ${ComponentComponentsExpandableList}
                        ${ComponentComponentsGetInTouch}
                        ${ComponentComponentsRoadmap}
                        ${ComponentComponentsServiceSlider}
                        ${ComponentComponentsTeamPhotos}
                        ${ComponentComponentsTechStackSlider}
                        ${ComponentGeneratorComponentsBorderedCard}
                        ${ComponentGeneratorComponentsBorderedCardList}
                        ${ComponentGeneratorComponentsCounterCardList}
                        ${ComponentGeneratorComponentsHeading}
                        ${ComponentGeneratorComponentsInfoCardList}
                        ${ComponentGeneratorComponentsList}
                        ${ComponentGeneratorComponentsOperationsList}
                        ${ComponentGeneratorComponentsQuoteBlock}
                        ${ComponentGeneratorComponentsServiceCardList}
                        ${ComponentGeneratorComponentsSolutionsList}
                        ${ComponentGeneratorComponentsStepsList}
                        ${ComponentGeneratorComponentsSupportCardList}
                        ${ComponentSectionsBlog}
                        ${ComponentSectionsCaseOverview}
                        ${ComponentSectionsCertificates}
                        ${ComponentSectionsClutchAchievements}
                        ${ComponentSectionsCustomersThink}
                        ${ComponentSectionsFrameworkLayerDiagram}
                        ${ComponentSectionsHero}
                        ${ComponentSectionsModels}
                        ${ComponentSectionsProjectsSlider}
                        ${ComponentSectionsPuzzlePlatform}
                        ${ComponentSectionsQuoteBlockWithImages}
                        ${ComponentSectionsShowcases}
                        ${ComponentSectionsTrustedBy}
                        ${ComponentSectionsWeProvide}
                        ${ComponentComponentsClutchReviewsWidget}
                    }
                }
            }
        }
    }
`

const get = async ({
  slug,
  locale,
  publicationState = PublicationState.Published
}: {
  slug: string
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<AboutUsPagesContainerProps>> =>
  graphqlRequest(document, { slug, locale, publicationState }).then((res) => res?.data?.aboutUsPages?.data?.[0])

export default get
