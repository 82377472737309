import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import graphqlRequest from '../request'
import { HeaderProps } from '../../../components/Header'

const document = `
 query Header($locale: I18NLocaleCode!, $publicationState: PublicationState!) {
    header(locale: $locale, publicationState: $publicationState) {
        data {
            id
            attributes {
                Links {
                    url
                    label
                }
                getInTouchRedirectUrl
            }
        }
    }
 }
`

const getHeader = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<HeaderProps>> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.header?.data)

export default getHeader
