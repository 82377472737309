import React, { useMemo } from 'react'
import type { AppContext, AppProps } from 'next/app'
import { Box, CssBaseline, ThemeProvider, Tooltip, createCache, CacheProvider } from '@hermes/web-components'
import dayjs from 'dayjs'
import { IntlProvider } from 'react-intl'
import Head from 'next/head'
import LogoIcon from '@hermes/web-components/src/icons/Logo'
import { useRouter } from 'next/router'
import { prefixer } from 'stylis'
import Script from 'next/script'
import { getConstants, getFooter, getHeader } from '../api'
import Footer from '../components/Footer'
import swiperMinCss from '../styles/swiper.min.css'
import getTheme from '../utils/getTheme'
import { AppConfigProvider } from '../providers/AppConfigProvider'
import getLocalesConfig from '../utils/getLocalesConfig'
import '@splidejs/react-splide/css'
import { GlobalStateProvider } from '../providers/GlobalStateProvider'
import getDomainName from '../utils/getDomainName'
import { PublicationState } from '../types/strapi'
import Header from '../components/Header'
import { MUI_CACHE_KEY } from '../constants/global'
import CookieConsentBannerLocker from '../components/CookieConsentBannerLocker'

function App({
  Component,
  pageProps,
  headerSettings,
  footerSettings,
  translations,
  locales,
  strapiConstants
}: AppProps) {
  const router = useRouter()
  const theme = getTheme()
  const domainName = getDomainName()
  const currentUrl = `${domainName}${router.asPath}`

  const hideHeader = useMemo(
    () => !!strapiConstants.hideHeaderUrls?.some((item) => router.asPath.includes(item.url)),
    [router.asPath, strapiConstants.hideHeaderUrls]
  )

  const emotionCache = createCache({
    key: MUI_CACHE_KEY,
    stylisPlugins: [prefixer],
    speedy: true
  })

  dayjs().locale(locales.locale)

  return (
    <CacheProvider value={emotionCache}>
      <AppConfigProvider locales={locales} constants={strapiConstants} isPreview={router.isPreview}>
        <Head>
          <link rel="canonical" href={currentUrl} />
          {/* eslint-disable-next-line @next/next/next-script-for-ga */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-BPRBC0Z9V7" />
          <script
            id="google-tag-manager"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag("js", new Date());
              gtag("config", "G-BPRBC0Z9V7");
            `
            }}
          />
          <meta property="og:url" content={currentUrl} />
          <meta property="og:locale" content={locales.locale} />
          <meta property="og:site_name" content="DBBSoftware" />
          <style>{swiperMinCss}</style>
          <link
            rel="preload"
            as="font"
            href="/fonts/Roboto/woff/Roboto-Regular.woff"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            as="font"
            href="/fonts/Roboto/woff/Roboto-Bold.woff"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            as="font"
            href="/fonts/Roboto/woff/Roboto-Medium.woff"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <style>
            {`
            html {
              font-family: 'Roboto', sans-serif;
              font-display: swap;
            }
          `}
          </style>
        </Head>
        <Script
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/2897ed574b26a8c8ab309631/script.js"
        ></Script>
        <IntlProvider locale={locales.locale} messages={translations}>
          <ThemeProvider theme={theme}>
            <GlobalStateProvider>
              <CssBaseline />
              {!hideHeader && <Header links={headerSettings.Links} />}
              <Box style={{ position: 'relative' }}>
                <Component {...pageProps} />
              </Box>
              <Footer links={headerSettings.Links} {...footerSettings} />
            </GlobalStateProvider>
          </ThemeProvider>
        </IntlProvider>
        <Box sx={{ overflow: 'hidden', width: 0, height: 0 }}>
          <LogoIcon id="app-logo" />
          <LogoIcon
            id="app-logo-black"
            sx={{
              path: {
                fill: 'black'
              }
            }}
          />
        </Box>
        <CookieConsentBannerLocker />
        {router.isPreview && (
          <Tooltip title="Preview Mode">
            <svg
              style={{
                position: 'fixed',
                bottom: '30px',
                right: '30px',
                zIndex: 10
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="44"
              viewBox="0 0 24 24"
            >
              <path
                fill="red"
                d="M12.884 2.532c-.346-.654-1.422-.654-1.768 0l-9 17A.999.999 0 0 0 3 21h18a.998.998 0 0 0 .883-1.467L12.884 2.532zM13 18h-2v-2h2v2zm-2-4V9h2l.001 5H11z"
              ></path>
            </svg>
          </Tooltip>
        )}
      </AppConfigProvider>
    </CacheProvider>
  )
}

App.getInitialProps = async ({ ctx, Component }: AppContext) => {
  const locales = await getLocalesConfig(ctx.locale)
  const pageProps = (await Component.getInitialProps?.(ctx)) || {}
  const locale = locales.locale || locales.defaultLocale!
  const headerSettingsResponse = await getHeader({ locale, publicationState: PublicationState.Published })
  const footerSettingsResponse = await getFooter({ locale, publicationState: PublicationState.Published })
  const strapiConstants = await getConstants({ locale })
  const translations = await import(`../../i18n/locales/${locale}.json`)

  return {
    pageProps: {
      ...pageProps
    },
    headerSettings: headerSettingsResponse?.attributes,
    footerSettings: footerSettingsResponse?.attributes,
    strapiConstants: strapiConstants?.attributes,
    translations,
    locales
  }
}

export default App
