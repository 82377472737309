import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import {
  ComponentComponentsGetInTouch,
  ComponentSectionsBlog,
  ComponentSectionsClutchAchievements,
  ComponentSectionsCustomersThink,
  ComponentSectionsHero,
  ComponentSectionsModels,
  ComponentSectionsShowcases,
  ComponentSectionsTrustedBy,
  ComponentSectionsWeProvide
} from '../components'
import PageMetadata from '../components/metadata'
import graphqlRequest from '../request'

const document = `
  query HomeNew($locale: I18NLocaleCode!, $publicationState: PublicationState!) {
    homeNew(locale: $locale, publicationState: $publicationState) {
      data {
        id
        attributes {
            title
            description
            metadata ${PageMetadata}
            sections {
                __typename
                ${ComponentSectionsHero}
                ${ComponentSectionsCustomersThink}
                ${ComponentSectionsWeProvide}
                ${ComponentSectionsModels}
                ${ComponentSectionsShowcases}
                ${ComponentSectionsTrustedBy}
                ${ComponentSectionsBlog}
                ${ComponentComponentsGetInTouch}
                ${ComponentSectionsClutchAchievements}
            }
        }
      }
    }
  } 
`

const page = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<any>> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.homeNew?.data)

export default page
