import strapiLocales from '../../i18n/config.json'
import { Locales } from '../types/strapi'

const getLocalesConfig = async (routerLocale?: string): Promise<Locales> => {
  const loc = routerLocale || 'en'
  const strapiConfigLocales = strapiLocales.map((locale) => locale.code)
  const strapiConfigDefaultLocale = strapiLocales.find((locale) => locale.isDefault)!.code
  return {
    locale: loc === 'catchAll' ? strapiConfigDefaultLocale : loc,
    locales: strapiConfigLocales,
    defaultLocale: strapiConfigDefaultLocale
  }
}

export default getLocalesConfig
