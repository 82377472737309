import getConfigVariable from '../../utils/getConfigVariable'

const strapiUrl = getConfigVariable('STRAPI_URL')
const strapiToken = getConfigVariable('STRAPI_TOKEN')

const getSitemap = () =>
  fetch(`${strapiUrl}/api/sitemap/index.xml`, {
    headers: {
      Authorization: `Bearer ${strapiToken}`
    }
  })

export default getSitemap
