import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import { InsightsArticleProps } from '../../../types/article'
import graphqlRequest from '../request'
import PageMetadata from '../components/metadata'
import {
  ComponentComponentsCkEditor,
  ComponentComponentsFAQ,
  ComponentComponentsInsightsBookBanner,
  ComponentComponentsInsightsLinkBanner,
  ComponentComponentsInsightsQuoteBlock,
  ComponentComponentsMinoBanner,
  ComponentGeneratorComponentsHeading,
  ComponentSectionsModernContactSection,
  StrapiImageObject
} from '../components'

const document = `
  query BlogArticles($slug: String!, $locale: I18NLocaleCode, $publicationState: PublicationState!) {
    blogArticles(publicationState: $publicationState, filters: { slug: { eq: $slug } }, locale: $locale) {
      data {
        id
        attributes {
          updatedAt
          localizations {
               data {
                  attributes {
                    locale
                    slug
               }
            }
          }
          title
          metadata ${PageMetadata}
          createDate
          content
          description
          keywords
          components {
            __typename     
            ${ComponentComponentsMinoBanner}
            ${ComponentComponentsCkEditor}
            ${ComponentGeneratorComponentsHeading}
            ${ComponentComponentsInsightsLinkBanner}
            ${ComponentComponentsInsightsQuoteBlock}
            ${ComponentComponentsFAQ}
            ${ComponentComponentsInsightsBookBanner}
          }
          hero {
            title
            bannerImage ${StrapiImageObject}
            authorAvatar ${StrapiImageObject}
            authorName
            authorPosition
            authorLinkedinUrl
            authorEmail
          }
          contactForm {
            ${ComponentSectionsModernContactSection}
          }
          recommendations {
            data {
              id
              attributes {
                title
                description
                createdAt
                keywords
                slug
                previewImage {
                    data {
                        id
                        attributes {
                            width
                            height
                            url
                            name
                        }
                    }
                }
              }
            }
          }
        }
      }
    }
  }
`

const get = async ({
  slug,
  locale,
  publicationState = PublicationState.Published
}: {
  slug: string
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<InsightsArticleProps>> =>
  graphqlRequest(document, { slug, locale, publicationState }).then((res) => res?.data?.blogArticles?.data?.[0])

export default get
