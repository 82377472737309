import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import { IndustryProps } from '../../../containers/Industry'
import {
  ComponentAtomsTitle,
  ComponentComponentsGetInTouch,
  ComponentGeneratorComponentsList,
  ComponentGeneratorComponentsSolutionsList,
  ComponentSectionsHero,
  ComponentSectionsShowcases
} from '../components'
import PageMetadata from '../components/metadata'
import graphqlRequest from '../request'

const document = `
  query Industries($slug: String!, $locale: I18NLocaleCode, $publicationState: PublicationState!) {
    industries(
        publicationState: $publicationState
        filters: { 
            slug: { 
                eq: $slug 
            } 
        }
        locale: $locale
    ) {
      data {
        id
        attributes {
            title
            description
            metadata ${PageMetadata}
            components {
                __typename
                ${ComponentAtomsTitle}
                ${ComponentSectionsHero}
                ${ComponentSectionsShowcases}
                ${ComponentGeneratorComponentsSolutionsList}
                ${ComponentComponentsGetInTouch}
                ${ComponentGeneratorComponentsList}
            }
        }
      }
    }
  }
`

const get = async ({
  slug,
  locale,
  publicationState = PublicationState.Published
}: {
  slug: string
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<IndustryProps>> =>
  graphqlRequest(document, { slug, locale, publicationState }).then((res) => res?.data?.industries?.data?.[0])

export default get
