import { Theme } from '@mui/material/styles/createTheme'
import { Components } from '@mui/material/styles/components'
import {
  Accordion,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  Container,
  CssBaseLine,
  Table,
  TableCell,
  TextField
} from './components'

export default function getOverrides(theme: Theme): Components {
  return {
    MuiCssBaseline: CssBaseLine(theme),
    MuiTextField: TextField(theme),
    MuiButton: Button(theme),
    MuiChip: Chip(theme),
    MuiContainer: Container(),
    MuiCheckbox: Checkbox(),
    MuiTable: Table(theme),
    MuiTableCell: TableCell(theme),
    MuiAccordion: Accordion(theme),
    MuiAccordionSummary: AccordionSummary()
  }
}
