import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function TextField(theme: Theme): Components['MuiTextField'] {
  return {
    styleOverrides: {
      root: {
        width: '100%',
        '.MuiOutlinedInput-root': {
          borderRadius: '25px',
          padding: '20px 25px',
          border: 'none',
          backgroundColor: theme.palette.background.default,
          input: {
            padding: 0,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            fontVariant: 'caps',
            fontStyle: 'normal',
            color: theme.palette.typography.primary,
            '&::placeholder': {
              opacity: 1,
              color: theme.palette.typography.secondary
            }
          }
        },
        '.MuiInputBase-multiline': {
          padding: '10px 0 4px 0'
        },
        '.MuiFilledInput-root': {
          backgroundColor: theme.palette.background.default,
          '&::before': {
            borderBottom: `1px solid ${theme.palette.typography.secondary}`
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${theme.palette.typography.secondary}`
          },
          '&:after': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
          },
          '&:hover': {
            backgroundColor: theme.palette.background.default
          },
          input: {
            padding: '10px 0 4px 0',
            '&:hover': {
              borderBottom: 'transparent'
            }
          }
        },
        fieldset: {
          border: 'none'
        },
        '&:hover fieldset': {
          border: 'none'
        }
      }
    }
  }
}
