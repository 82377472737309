export * from './rest/contact'

export { default as getBlog } from './graphql/blog/get'
export { default as findBlogs, type FindBlogsResponse } from './graphql/blog/find'
export { default as getBlogsPage } from './graphql/blog/page'
export { default as getBlogsRelated } from './graphql/blog/related'

export { default as getShowcase } from './graphql/showcases/get'
export { default as findShowcases, type FindShowcasesResponse } from './graphql/showcases/find'
export { default as getShowcasesPage } from './graphql/showcases/page'
export { default as getShowcasesRelated } from './graphql/showcases/related'

export { default as findEmployees, type FindEmployeesResponse } from './graphql/employees/find'
export { default as getEmployeesPage } from './graphql/employees/page'

export { default as getIndustry } from './graphql/industries/get'
export { default as findIndustries } from './graphql/industries/find'
export { default as getIndustriesPage } from './graphql/industries/page'

export { default as getService } from './graphql/services/get'
export { default as findServices } from './graphql/services/find'
export { default as getServicesPage } from './graphql/services/page'

export { default as getHomePage } from './graphql/home/page'

export { default as getHeader } from './graphql/app/header'

export { default as getFooter } from './graphql/app/footer'

export { default as getConstants } from './graphql/app/constants'

export { default as getLanding } from './graphql/landing/get'

export { default as getProjects } from './graphql/projects/get'

export { default as getProposal } from './graphql/proposal/get'

export { default as getSitemap } from './rest/sitemap'

export { default as getAboutUsPages } from './graphql/aboutUsPages/get'
