import { Theme } from '@mui/material/styles/createTheme'

export default function Typography(theme: Theme) {
  return {
    htmlFontSize: 14,
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '40px',
      fontWeight: 400,
      lineHeight: '57px'
    },
    h2: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 700,
      fontSize: '28px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '21.36px'
      }
    },
    h3: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '24px',
      fontWeight: 400
    },
    h4: {
      fontSize: '20px',
      fontWeight: 400
    },
    h5: {
      fontSize: '16.88px',
      fontWeight: 500
    },
    h6: {},
    body1: {
      fontSize: '16px',
      fontFamily: 'Roboto, sans-serif',
      wordSpacing: '1px',
      lineHeight: '22px',
      fontWeight: 400
    },
    body2: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '20px',
      lineHeight: 'normal',
      wordSpacing: '1px'
    },
    subtitle1: {},
    subtitle2: {},
    button: {},
    caption: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      lineHeight: '16.41px'
    },
    overline: {},
    pxToRem: () => 'null'
  }
}
