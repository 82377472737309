import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Accordion(theme: Theme): Components['MuiAccordion'] {
  return {
    styleOverrides: {
      root: {
        '::before': {
          display: 'none'
        },
        boxShadow: 'none',
        backgroundColor: theme.palette.background.default
      }
    }
  }
}
