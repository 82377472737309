import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import { IndustryProps } from '../../../containers/Industry'
import graphqlRequest from '../request'

const document = `
  query Industries($locale: I18NLocaleCode, $publicationState: PublicationState!) {
    industries(
        locale: $locale
        publicationState: $publicationState
    ) {
      data {
        id
        attributes {
            title
            description
            slug
            createdAt
            previewImage {
                data {
                    attributes {
                        width
                        height
                        url
                        name
                        formats
                    }
                }
            }
        }
      }
    }
  }
`

const find = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState?: PublicationState
}): Promise<StrapiAttributes<IndustryProps>[]> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.industries?.data)

export default find
