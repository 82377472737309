import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Chip(theme: Theme): Components['MuiChip'] {
  return {
    styleOverrides: {
      root: {
        width: '126px',
        cursor: 'pointer',
        borderRadius: '25px',
        height: 'auto',
        padding: '12px 0',
        '&:hover': {
          backgroundColor: theme.palette.primary.main
        },
        span: {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '30px'
        }
      },
      filled: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.typography.main
      },
      outlined: {
        border: `1px solid ${theme.palette.typography.primary}`
      }
    }
  }
}
