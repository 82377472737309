import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import graphqlRequest from '../request'
import { FooterProps } from '../../../components/Footer'

const document = `
    query Footer($locale: I18NLocaleCode!, $publicationState: PublicationState!) {
        footer(locale: $locale, publicationState: $publicationState) {
            data {
                id
                attributes {
                    email
                    telephone
                    linksTree {
                        title
                        id
                        alsoForMobile
                        links {
                            id
                            label
                            url
                        }
                    }
                    socials {
                        id
                        label
                        link
                        icon {
                            data {
                                id
                                attributes {
                                    height
                                    width
                                    name
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const getFooter = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<FooterProps>> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.footer?.data)

export default getFooter
