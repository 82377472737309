import { StrapiImageObject } from './index'

const PageMetadata = `
    {
        title
        ogTitle
        twitterTitle
        description
        keywords
        ogType
        ogImage ${StrapiImageObject}
        twitterImage ${StrapiImageObject}
        twitterCard
        blockIndexing
    }
`

export default PageMetadata
