import { Components } from '@mui/material/styles/components'

export default function Container(): Components['MuiContainer'] {
  return {
    styleOverrides: {
      root: {
        '&.MuiContainer-maxWidthLg': {
          maxWidth: '1200px'
        }
      }
    }
  }
}
