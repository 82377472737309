import { Container, Grid, Hidden, Typography, useMediaQuery, useTheme } from '@hermes/web-components'
import React, { useRef } from 'react'
import { SxProps } from '@mui/system'
import NextLink from 'next/link'
import NextImage from 'next/image'
import { FooterContainer, FooterLiner, FooterLink, FooterWrapper, SocialLinkWrapper } from './styles'
import useTranslateMessage from '../../hooks/useTranslateMessage'
import { StrapiMedia, StrapiResponse } from '../../types/strapi'

export interface FooterProps {
  linksTree: {
    title: string
    id: string
    alsoForMobile: boolean
    links: {
      id: string
      label: string
      url: string
    }[]
  }[]
  socials: {
    id: string
    label: string
    link: string
    icon: StrapiResponse<StrapiMedia>
  }[]
}

const SocialLink = ({ icon, label, href, sx }: { icon?: string; label: string; href?: string; sx?: SxProps }) => (
  <SocialLinkWrapper
    aria-label={label}
    component={FooterLink}
    href={href}
    target="_blank"
    sx={{ textDecoration: 'none', ...sx }}
  >
    {icon && <NextImage src={icon} width={20} height={20} alt={label} />}
  </SocialLinkWrapper>
)

const FooterLinkOrAction = ({ label, href, sx }: { label: string; href: string; sx?: SxProps }) => {
  const action = href?.includes('!') ? 'onClick' : 'link'
  const footerLinkRef = useRef<HTMLAnchorElement | null>(null)

  return action === 'onClick' ? (
    <FooterLink
      ref={footerLinkRef}
      component="div"
      className={href === '!cookie-settings-popup' ? 'cky-banner-element' : ''}
      sx={{ textDecoration: 'none', cursor: 'pointer', ...sx }}
    >
      <Typography>{label}</Typography>
    </FooterLink>
  ) : (
    <NextLink href={href} passHref>
      <FooterLink ref={footerLinkRef} sx={{ textDecoration: 'none', ...sx }}>
        <Typography>{label}</Typography>
      </FooterLink>
    </NextLink>
  )
}

const Footer = ({ linksTree, socials }: FooterProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const t = useTranslateMessage()

  return (
    <FooterContainer>
      <span itemProp="name" style={{ display: 'none' }}>
        DBB Software
      </span>
      <FooterWrapper maxWidth="lg" itemScope itemType="http://schema.org/Organization">
        <Grid maxWidth={isMobile ? '100%' : '252px'} container position="relative" direction="column" gap="30px">
          <svg height="55" width="110">
            <use xlinkHref="#app-logo-black" />
          </svg>
          <Grid container gap="12px" justifyContent="space-between" width={isMobile ? '100%' : '110px'}>
            {socials?.map((social) => (
              <SocialLink
                key={`social-link-id-${social.id}`}
                icon={social.icon.data.attributes.url}
                label={social.label}
                href={social.link}
              />
            ))}
          </Grid>
        </Grid>
        {linksTree?.map(({ title, id, links, alsoForMobile }) => (
          <Grid
            component={Hidden}
            implementation="css"
            smDown={!alsoForMobile}
            key={`footer-links-tree-${id}`}
            maxWidth="252px"
            container
            position="relative"
            direction="column"
            gap="30px"
          >
            <Typography
              implementation="css"
              component={Hidden}
              smDown
              variant="h4"
              color="typography.primary"
              fontWeight={500}
              textTransform="uppercase"
            >
              {title}
            </Typography>
            <Grid container mr={isMobile ? '30%' : 0} gap="12px" width="max-content" flexDirection="column">
              {links.map(({ id: linkId, label, url }) => (
                <FooterLinkOrAction key={`footer-link-id-${linkId}`} href={url} label={label} />
              ))}
            </Grid>
          </Grid>
        ))}
      </FooterWrapper>
      <FooterLiner>
        <Grid component={Container} container py="30px">
          <Typography maxWidth="300px">
            {t('footer.copyRight', {
              year: new Date().getFullYear()
            })}
          </Typography>
        </Grid>
      </FooterLiner>
    </FooterContainer>
  )
}

export default Footer
