import React, { SVGProps } from 'react'

const CheckboxChecked = (props: SVGProps<any>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M2.5.5h10a2 2 0 012 2v10a2 2 0 01-2 2h-10a2 2 0 01-2-2v-10a2 2 0 012-2z"
        transform="translate(3 3)"
      ></path>
      <path d="M4.5 7.5l2 2 4-4" transform="translate(3 3)"></path>
    </g>
  </svg>
)

export default CheckboxChecked
