import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import PageMetadata from '../components/metadata'
import graphqlRequest from '../request'

const document = `
    query Services($locale: I18NLocaleCode, $publicationState: PublicationState!) {
        servicesPage(locale: $locale, publicationState: $publicationState) {
            data {
                id
                attributes {
                    title
                    description
                    metadata ${PageMetadata}
                    teamImages {
                        data {
                            id
                            attributes {
                                width
                                height
                                url
                                name
                                formats
                            }
                        }
                    }
                    cta {
                        title
                        description
                    }
                }
            }
        }
    }
`

const getServicesPage = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<any>> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.servicesPage?.data)

export default getServicesPage
