import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import {
  ComponentAtomsRichText,
  ComponentAtomsTitle,
  ComponentComponentsContactForm,
  ComponentComponentsExpandableList,
  ComponentComponentsGetInTouch,
  ComponentComponentsRoadmap,
  ComponentComponentsServiceSlider,
  ComponentComponentsTeamPhotos,
  ComponentGeneratorComponentsBorderedCardList,
  ComponentGeneratorComponentsCounterCardList,
  ComponentGeneratorComponentsInfoCardList,
  ComponentGeneratorComponentsList,
  ComponentGeneratorComponentsOperationsList,
  ComponentGeneratorComponentsQuoteBlock,
  ComponentGeneratorComponentsServiceCardList,
  ComponentGeneratorComponentsSolutionsList,
  ComponentGeneratorComponentsStepsList,
  ComponentGeneratorComponentsSupportCardList,
  ComponentSectionsClutchAchievements,
  ComponentSectionsCustomersThink,
  ComponentSectionsHero,
  ComponentSectionsQuoteBlockWithImages,
  ComponentSectionsProjectsSlider,
  ComponentSectionsShowcases,
  ComponentSectionsTrustedBy
} from '../components'
import PageMetadata from '../components/metadata'
import { ServiceProps } from '../../../containers/Service'
import graphqlRequest from '../request'

const document = `
  query Services($slug: String!, $locale: I18NLocaleCode, $publicationState: PublicationState!) {
    services(
        publicationState: $publicationState
        filters: { 
            slug: { 
                eq: $slug 
            } 
        }
        locale: $locale
    ) {
      data {
        id
        attributes {
            title
            description
            metadata ${PageMetadata}
            components {
                __typename
                ${ComponentAtomsTitle}
                ${ComponentSectionsHero}
                ${ComponentSectionsShowcases}
                ${ComponentGeneratorComponentsSolutionsList}
                ${ComponentComponentsGetInTouch}
                ${ComponentGeneratorComponentsList}
                ${ComponentSectionsCustomersThink}
                ${ComponentComponentsContactForm}
                ${ComponentGeneratorComponentsCounterCardList}
                ${ComponentGeneratorComponentsQuoteBlock}
                ${ComponentGeneratorComponentsBorderedCardList}
                ${ComponentSectionsTrustedBy}
                ${ComponentComponentsTeamPhotos}
                ${ComponentGeneratorComponentsInfoCardList}
                ${ComponentComponentsRoadmap}
                ${ComponentSectionsClutchAchievements}
                ${ComponentAtomsRichText}
                ${ComponentGeneratorComponentsStepsList}
                ${ComponentGeneratorComponentsSupportCardList}
                ${ComponentSectionsQuoteBlockWithImages}
                ${ComponentSectionsProjectsSlider}
                ${ComponentGeneratorComponentsOperationsList}
                ${ComponentGeneratorComponentsServiceCardList}
                ${ComponentComponentsExpandableList}
                ${ComponentComponentsServiceSlider}
            }
        }
      }
    }
  }
`
const get = async ({
  slug,
  locale,
  publicationState = PublicationState.Published
}: {
  slug: string
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<ServiceProps>> =>
  graphqlRequest(document, { slug, locale, publicationState }).then((res) => res?.data?.services?.data?.[0])

export default get
