import { PublicationState } from '../../../types/strapi'
import graphqlRequest from '../request'
import { StrapiImageObject } from '../components'
import { ProjectsResponse } from '../../../types/projects'

const document = `
  query Projects($locale: I18NLocaleCode, $publicationState: PublicationState!, $filters: ProjectFiltersInput!) {
    projects(publicationState: $publicationState, locale: $locale, filters: $filters, pagination: {
        limit: 20
    }) {
        data {
            id
            attributes {
                title
                image ${StrapiImageObject}
                descriptionList
                readMoreLinks {
                    label
                    url
                }
                projectType
            }
        }
    }
  }
`

const get = async ({
  locale,
  publicationState = PublicationState.Published,
  type = 'website'
}: {
  locale: string
  publicationState: PublicationState
  type?: string
}): Promise<ProjectsResponse> => {
  const filters = type === 'all' ? {} : { projectType: { eq: type } }
  return graphqlRequest(document, { locale, publicationState, filters }).then((res) => res?.data?.projects?.data)
}

export default get
