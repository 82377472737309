import { createContext, ReactNode, useContext } from 'react'
import { Locales } from '../types/strapi'
import { GlobalConstants } from '../types/constants'

interface AppConfigContextProps extends Locales {
  isPreview: boolean
  constants: GlobalConstants
}

const AppConfigContext = createContext<AppConfigContextProps>({
  locale: '',
  locales: [],
  defaultLocale: '',
  isPreview: false,
  constants: {
    bookCallUrl: '',
    thankYouUrl: ''
  }
} as AppConfigContextProps)

const AppConfigProvider = ({
  children,
  locales,
  isPreview,
  constants
}: {
  children: ReactNode
  locales: Locales
  isPreview: boolean
  constants: GlobalConstants
}) => (
  <AppConfigContext.Provider
    value={{
      ...locales,
      isPreview,
      constants
    }}
  >
    {children}
  </AppConfigContext.Provider>
)

const useAppConfig = () => {
  const context = useContext(AppConfigContext)
  if (context === undefined) {
    throw new Error('useAppConfig must be used within a AppConfigProvider')
  }
  return context
}

export { useAppConfig, AppConfigProvider }
