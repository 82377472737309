import React, { useCallback, useEffect, useState } from 'react'
import { Backdrop } from '@hermes/web-components'

const CookieConsentBannerLocker = () => {
  const [consentBackdropOpen, setConsentBackdropOpen] = useState(false)

  const handleBannerMutation = useCallback((cookieYesBanner: HTMLDivElement) => {
    if (!cookieYesBanner) return

    if (cookieYesBanner.classList.contains('cky-hide')) {
      setConsentBackdropOpen(false)
    } else {
      setConsentBackdropOpen(true)
    }
  }, [])

  const observeCookieBanner = useCallback(() => {
    const cookieYesBanner = document.getElementsByClassName('cky-consent-container')[0] as HTMLDivElement

    if (!cookieYesBanner) return null
    handleBannerMutation(cookieYesBanner)

    const observer = new MutationObserver(() => handleBannerMutation(cookieYesBanner))

    observer.observe(cookieYesBanner, {
      attributes: true,
      attributeFilter: ['class']
    })

    return observer
  }, [])

  useEffect(() => {
    if (!document) return

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const cookieObserver = observeCookieBanner()
          if (cookieObserver) {
            observer.disconnect()
          }
        }
      })
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  return <Backdrop sx={{ zIndex: 999 }} open={consentBackdropOpen} />
}

export default CookieConsentBannerLocker
