import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import PageMetadata from '../components/metadata'
import graphqlRequest from '../request'

const document = `
    query ShowcasesPage($locale: I18NLocaleCode!, $publicationState: PublicationState!) {
        showcasesPage(locale: $locale, publicationState: $publicationState) {
            data {
                id
                attributes {
                    title
                    description
                    metadata ${PageMetadata}
                    hero {
                      id
                      variant
                      contactButton
                      subtitle
                      title
                  }
                  cta {
                      title
                      description
                  }
                }
            }
        }
    }
`

const getShowcasesPage = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<any>> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.showcasesPage?.data)

export default getShowcasesPage
