import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function TableCell(theme: Theme): Components['MuiTableCell'] {
  return {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.palette.typography.secondary}`,
        borderRight: `1px solid ${theme.palette.typography.secondary}`
      }
    }
  }
}
