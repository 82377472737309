import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import { IndustryProps } from '../../../containers/Industry'
import graphqlRequest from '../request'

const document = `
    query Services($locale: I18NLocaleCode, $publicationState: PublicationState!) {
        services(
            publicationState: $publicationState
            locale: $locale
            pagination: { limit: -1 }
        ) {
            data {
                id
                attributes {
                    title
                    description
                    slug
                    icon {
                        data {
                            attributes {
                                width
                                height
                                name
                                url
                                formats
                            }
                        }
                    }
                }
            }
        }
    }
`

const find = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState?: PublicationState
}): Promise<StrapiAttributes<IndustryProps>[]> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.services?.data)

export default find
