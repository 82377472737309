import { StrapiAttributes } from '../../../types/strapi'
import graphqlRequest from '../request'
import { GlobalConstants } from '../../../types/constants'

const document = `
 query Constants($locale: I18NLocaleCode!) {
    constant(locale: $locale) {
        data {
            id
            attributes {
                bookCallUrl
                thankYouUrl
                hideHeaderUrls {
                    url
                }
            }
        }
    }
 }
`

const getConstants = async ({ locale }: { locale: string }): Promise<StrapiAttributes<GlobalConstants>> =>
  graphqlRequest(document, { locale }).then((res) => res?.data?.constant?.data)

export default getConstants
