import { PublicationState, StrapiAttributes, StrapiResponseWithPagination } from '../../../types/strapi'
import { EmployeeCardProps } from '../../../components/EmployeeCard'
import graphqlRequest from '../request'

const document = `
  query Employees($locale: I18NLocaleCode!, $page: Int!, $pageSize: Int!, $publicationState: PublicationState!) {
    employees(
        publicationState: $publicationState,
        locale: $locale, 
        pagination: {
            page: $page
            pageSize: $pageSize
        }
    ) {
        data {
            attributes {
                avatar {
                    data {
                        id
                        attributes {
                            width
                            hash
                            url
                            formats
                        }
                    }
                }
                createdAt
                direction
                fullName
                readyToWork
                englishLevel
                experienceLevel
                experienceYears
                workingHours
                cvLink
                techList {
                    technology
                    yearsOfExperience
                }
                
            }
        }
        meta {
            pagination {
                page
                pageCount
                pageSize
            }
        }
    }
  } 
`

export type FindEmployeesResponse = StrapiResponseWithPagination<StrapiAttributes<EmployeeCardProps>[]>

const find = async ({
  locale,
  page,
  pageSize,
  publicationState = PublicationState.Published
}: {
  locale: string
  page: number
  pageSize: number
  publicationState: PublicationState
}): Promise<FindEmployeesResponse> =>
  graphqlRequest(document, { locale, page, pageSize, publicationState }).then((res) => res?.data?.employees)

export default find
