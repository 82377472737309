import { PublicationState, StrapiAttributes, StrapiResponseWithPagination } from '../../../types/strapi'
import { ArticleCardProps } from '../../../types/article'
import graphqlRequest from '../request'

const document = `
    query BlogArticles(
        $query: String, 
        $locale: I18NLocaleCode, 
        $page: Int!
        $pageSize: Int!
        $startDate: Date
        $endDate: Date
        $publicationState: PublicationState!
    ) {
      blogArticles(
            publicationState: $publicationState
            sort: "createDate:DESC" 
            filters: {
                or: [
                  {
                    title: {
                        containsi: $query
                    }
                  },
                  {
                    keywords: {
                        containsi: $query
                    }
                  }
                ]
                createDate: {
                    gte: $startDate
                    lte: $endDate
                }
            }, 
            locale: $locale
            pagination: {
                page: $page
                pageSize: $pageSize
            }
        ) {
        data {
          id
          attributes {
            title
            description
            createDate
            keywords
            slug
            previewImage {
                data {
                    id
                    attributes {
                        width
                        height
                        url
                        name
                    }
                }
            }
          }
        }
        meta {
            pagination {
                page
                pageSize
                pageCount
            }
        }
      }
    }
`

export type FindBlogsResponse = StrapiResponseWithPagination<StrapiAttributes<ArticleCardProps>[]>

const find = async ({
  locale,
  query,
  page,
  pageSize,
  startDate,
  endDate,
  publicationState = PublicationState.Published
}: {
  locale: string
  query?: string | string[]
  page: number
  pageSize: number
  startDate?: string
  endDate?: string
  publicationState?: PublicationState
}): Promise<FindBlogsResponse> =>
  graphqlRequest(document, { locale, query, page, pageSize, startDate, endDate, publicationState }).then(
    (res) => res?.data?.blogArticles
  )

export default find
