import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import PageMetadata from '../components/metadata'
import graphqlRequest from '../request'

const document = `
  query EmployeesPage($locale: I18NLocaleCode!, $publicationState: PublicationState!) {
    employeePage(locale: $locale, publicationState: $publicationState) {
        data {
            id
            attributes {
                title
                description
                metadata ${PageMetadata}
                headingImage {
                    data {
                        id
                        attributes {
                            width
                            height
                            url
                            name
                            formats
                        }
                    }
                }
            }
        }
    }
  } 
`

const getEmployeesPage = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<any>> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.employeePage?.data)

export default getEmployeesPage
