import { Box, Button, Grid, Hidden, Link, MenuItem, Typography, useTheme } from '@hermes/web-components'
import MenuIcon from '@hermes/web-components/src/icons/Menu'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import { HeaderContainer, HeaderContent, HeaderLinks, MobileMenu, NavButton } from './styles'
import useTranslateMessage from '../../hooks/useTranslateMessage'
import { useAppConfig } from '../../providers/AppConfigProvider'
import { useGlobalState } from '../../providers/GlobalStateProvider'

export interface HeaderProps {
  links: {
    url: string
    label: string
  }[]
  getInTouchRedirectUrl?: string
}

const Header = ({ links }: HeaderProps) => {
  const { locales, locale } = useAppConfig()
  const { headerLocalizationLinks } = useGlobalState()
  const theme = useTheme()
  const router = useRouter()
  const t = useTranslateMessage()
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(menuAnchor)
  const [isScrolled, setIsScrolled] = useState(false)
  const isHomePage = router.pathname === '/'

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 1)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (window?.document) {
      const { body } = window.document

      const observer = new MutationObserver(() => {
        body.style.touchAction = body.style.overflow === 'hidden' ? 'none' : ''
      })

      observer.observe(body, {
        attributes: true,
        attributeFilter: ['style']
      })
    }
  }, [])

  const handleClickMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget)
  }

  const handleCloseMenu = () => {
    setMenuAnchor(null)
  }

  const LocaleLinks = (
    <>
      {locales && locales.length > 1 && (
        <HeaderLinks
          sx={{ marginLeft: '30px', gap: '12px', height: '100%', alignItems: 'stretch', alignContent: 'stretch' }}
        >
          {headerLocalizationLinks.map(({ href, label }) => (
            <NavButton href={href} key={label} active={label.toLowerCase() === locale}>
              {label}
            </NavButton>
          ))}
        </HeaderLinks>
      )}
    </>
  )

  const DesktopNavigation = (
    <>
      {links?.length ? (
        <HeaderLinks height="100%">
          {links.map(({ url, label }) => (
            <NextLink passHref href={url} key={label} prefetch>
              <NavButton active={router.asPath.includes(url)}>{label}</NavButton>
            </NextLink>
          ))}
        </HeaderLinks>
      ) : (
        <div />
      )}
    </>
  )

  const MobileNavigation = (
    <>
      {links?.length ? (
        <>
          <Button
            variant="icon"
            disableRipple
            id="menu-anchor"
            aria-controls={menuAnchor ? 'mobile-menu' : undefined}
            aria-haspopup="true"
            aria-label="menu-button-anchor"
            title="menu-button-anchor"
            aria-expanded={menuAnchor ? 'true' : undefined}
            onClick={handleClickMenu}
          >
            <MenuIcon width="24px" fill={theme.palette.typography.main} />
          </Button>
          <MobileMenu
            id="mobile-menu"
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleCloseMenu}
            disableRestoreFocus={true}
            MenuListProps={{
              'aria-labelledby': 'menu-anchor'
            }}
          >
            {links.map(({ url, label }) => (
              <NextLink href={url} key={`menu-link-${label}`}>
                <Link
                  sx={{
                    textDecoration: 'none'
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu()
                    }}
                  >
                    <Typography fontSize="14px" color="typography.primary">
                      {label}
                    </Typography>
                  </MenuItem>
                </Link>
              </NextLink>
            ))}
          </MobileMenu>
        </>
      ) : (
        <div />
      )}
    </>
  )

  return (
    <HeaderContainer scrolled={isScrolled}>
      <HeaderContent maxWidth="lg">
        <Box display="inline-flex">
          {isHomePage ? (
            <Box
              aria-label={`home-page-${locale}`}
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <svg width="79" height="41">
                <use xlinkHref="#app-logo" />
              </svg>
            </Box>
          ) : (
            <NextLink href="/" passHref>
              <Link
                aria-label={`home-page-${locale}`}
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <svg width="79" height="41">
                  <use xlinkHref="#app-logo" />
                </svg>
              </Link>
            </NextLink>
          )}
          <Hidden implementation="css" mdDown>
            {LocaleLinks}
          </Hidden>
        </Box>
        <Hidden mdUp implementation="css">
          <Grid
            container
            gap="30px"
            sx={{
              width: '100%',
              height: '100%',
              justifyContent: 'end',
              alignItems: 'center',
              alignContent: 'center'
            }}
          >
            <Grid
              sx={{
                height: '100%'
              }}
              item
            >
              {LocaleLinks}
            </Grid>
            <Grid item>{MobileNavigation}</Grid>
          </Grid>
        </Hidden>
        <Hidden mdDown implementation="css">
          {DesktopNavigation}
        </Hidden>
        <Hidden mdDown implementation="css">
          <NextLink href="/contact">
            <a>
              <Button variant="gradientModern">{t('contactUs')}</Button>
            </a>
          </NextLink>
        </Hidden>
      </HeaderContent>
    </HeaderContainer>
  )
}

export default Header
