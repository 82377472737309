import { PublicationState, StrapiAttributes } from '../../../types/strapi'
import PageMetadata from '../components/metadata'
import graphqlRequest from '../request'

const document = `
    query Industries($locale: I18NLocaleCode, $publicationState: PublicationState!) {
        industriesPage(locale: $locale, publicationState: $publicationState) {
            data {
                id
                attributes {
                    title
                    description
                    metadata ${PageMetadata}
                    cta {
                        title
                        description
                    }
                }
            }
        }
    }
`

const getIndustriesPage = async ({
  locale,
  publicationState = PublicationState.Published
}: {
  locale: string
  publicationState: PublicationState
}): Promise<StrapiAttributes<any>> =>
  graphqlRequest(document, { locale, publicationState }).then((res) => res?.data?.industriesPage?.data)

export default getIndustriesPage
